import { render, staticRenderFns } from "./DetailInterPresiView.vue?vue&type=template&id=6a533068&scoped=true"
import script from "./DetailInterPresiView.vue?vue&type=script&lang=js"
export * from "./DetailInterPresiView.vue?vue&type=script&lang=js"
import style0 from "./DetailInterPresiView.vue?vue&type=style&index=0&id=6a533068&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a533068",
  null
  
)

export default component.exports